const euStoreLocales = ['en-eu', 'en-ie', 'en-fr', 'en-de'];

module.exports = {
  euStoreLocales: [...euStoreLocales],
  i18n: {
    // add locales/{{locale}}/{{namespace}}.json files (empty file is ok) anytime a locale key is added
    // the fallback will only handle missing keys, not file
    locales: ['en-us', 'en-ca', 'en-au', 'en-nz', 'en-gb', ...euStoreLocales],
    defaultLocale: 'en-us',
  },
  localePath: './locales',
  ns: ['account', 'auth', 'blog', 'cart', 'checkout', 'common', 'countries', 'home', 'languages', 'product', 'plans'],
  defaultNS: 'common',
  lowerCaseLng: true, // https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
};
