import { NinetailedConfig } from '@services/ninetailed/types/config';

// allow public config named import
// the rule doesn't have an option to allow certain named import, hence this workaround
// eslint-disable-next-line no-restricted-imports
import { public as publicKeys } from '@config/web-commerce.config.json';

const { braze, ninetailed } = publicKeys;

export function getBrazeClientConfig(): { clientApiKey?: string; sdkEndpoint?: string } {
  return {
    clientApiKey: braze.clientApiKey,
    sdkEndpoint: braze.sdkEndpoint,
  };
}

export function getNinetailedClientConfig(): NinetailedConfig {
  return {
    apiKey: ninetailed.apiKey,
    mgmtClientId: ninetailed.mgmtClientId,
    mgmtSecret: ninetailed.mgmtSecret,
    environment: ninetailed.environment,
  };
}
