import type { ReactNode } from 'react';

import { CommerceConfig, CommerceProvider as CoreCommerceProvider, useCommerce as useCoreCommerce } from '@commerce';

import { BigcommerceProvider, bigcommerceProvider } from './provider';

export { bigcommerceProvider };
export type { BigcommerceProvider };

export const bigcommerceConfig: CommerceConfig = {
  locale: 'en-us',
  cartCookie: 'bc_cartId',
};

export type BigcommerceConfig = Partial<CommerceConfig>;

export type BigcommerceProps = {
  children?: ReactNode;
  locale: string;
} & BigcommerceConfig;

export function CommerceProvider({ children, ...config }: BigcommerceProps) {
  return (
    <CoreCommerceProvider provider={bigcommerceProvider} config={{ ...bigcommerceConfig, ...config }}>
      {children}
    </CoreCommerceProvider>
  );
}

export const useCommerce = () => useCoreCommerce<BigcommerceProvider>();
