import { FetcherError } from '@commerce/utils/errors';
import type { Fetcher } from '@commerce/utils/types';

async function getText(res: Response) {
  try {
    return (await res.text()) || res.statusText;
  } catch (error) {
    return res.statusText;
  }
}

async function getError(res: Response) {
  if (res.headers.get('Content-Type')?.includes('application/json')) {
    const err = await res.json();
    if (err?.errors) {
      return new FetcherError({ errors: err.errors, status: res.status });
    }
    return err?.data;
  }
  return new FetcherError({ message: await getText(res), status: res.status });
}

const fetcher: Fetcher = async ({ url, method = 'GET', variables, body: bodyObj }) => {
  const hasBody = Boolean(variables || bodyObj);
  const body = hasBody ? JSON.stringify(variables ? { variables } : bodyObj) : undefined;
  const headers = hasBody ? { 'Content-Type': 'application/json' } : undefined;
  const res = await fetch(url!, { method, body, headers });

  if (res.ok) {
    const { data } = res.status !== 204 ? await res.json() : { data: null };
    return data;
  }

  throw await getError(res);
};

export default fetcher;
