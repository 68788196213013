export const BIG_COMMERCE_CART_ID_COOKIE = 'TILE_BC_CART_ID';

export const BIG_COMMERCE_ORDER_ID_COOKIE = 'TILE_BC_ORDER_ID';

export const LAST_ADDED_SKU_LOCAL_STORAGE = 'LAST_ADDED_SKU';

export const CART_MAX_ITEM = 16;

export const CHECKOUT_DATA_LOCAL_STORAGE = 'TILE_CHECKOUT';

export const ORDER_METADATA_SESSION_STORAGE = 'TILE_ORDER_METADATA';

export const PREMIUM_SOURCE_SESSION_STORAGE = 'PREMIUM_SOURCE';

export const USER_LOGGED_IN_SESSION_STORAGE = 'TILE_USER_LOGGED_IN';

export const EMPTY_LAST_NAME_FALLBACK = 'N/A';

export const DR_CHECKOUT_ID_COOKIE = 'DR_CHECKOUT_ID';
