import fetcher from './fetcher';

export const bigcommerceProvider = {
  locale: 'en-us',
  cartCookie: 'bc_cartId',
  fetcher,
};

export type BigcommerceProvider = {
  coupon?: any;
} & typeof bigcommerceProvider;
