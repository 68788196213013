import get from 'lodash/get';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../tailwind.config';

export default function getConfig(path?: string) {
  const config = resolveConfig(tailwindConfig);
  if (!path) {
    return config;
  }

  return get(config, path);
}
