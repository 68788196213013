import { Component, ErrorInfo, ReactNode } from 'react';

import { Error } from '@components/common';
import { logger } from '@lib/logger';

class ErrorBoundary extends Component<{ children?: ReactNode }, { hasError: boolean }> {
  constructor(props: {}) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can use your own error logging service here
    logger.error(error);

    if (process.env.NEXT_PUBLIC_DEPLOY_ENV !== 'production') {
      logger.error({ error, errorInfo });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    // Return children components in case of no error
    if (!hasError) {
      return children;
    }

    return <Error statusCode={500} title="common:pageError.500" subtitle="common:pageError.internalErrorSubtitle" />;
  }
}

export default ErrorBoundary;
