import { CommerceError } from './CommerceError';
import { ErrorProps } from './types';

export class FetcherError extends CommerceError {
  status: number;

  constructor(
    options: {
      status: number;
    } & ErrorProps
  ) {
    super(options);
    this.status = options.status;
  }
}
