import { ToastContainer as ToastifyContainer } from 'react-toastify';

import s from './ToastContainer.module.scss';

export default function ToastContainer() {
  return (
    <ToastifyContainer
      position="top-center"
      className={s.toastContainer}
      toastClassName={s.toastWrapper}
      bodyClassName={s.toast}
    />
  );
}
