import { ItemListElements } from 'next-seo/lib/types';

import { buildAlternateReferences, buildLocalizedUrl, PathAlternatesSummary } from '@lib/get-alternates';
import { isDefaultLocaleIgnoreCase } from '@lib/locales';

export const buildCanonicalUrl = ({ origin, locale, path }: { origin: string; locale: string; path: string }) =>
  isDefaultLocaleIgnoreCase(locale)
    ? buildLocalizedUrl({ origin, locale: '', path })
    : buildLocalizedUrl({ origin, locale, path });

export const buildAlternateLangUrl = buildLocalizedUrl;

export const getSeoUrlConfig = ({
  origin,
  pathname,
  currentLocale,
  resourceAlternateSummary,
  locales,
  is404,
}: {
  origin: string;
  pathname: string;
  currentLocale: string;
  resourceAlternateSummary: PathAlternatesSummary | null;
  locales: string[];
  is404?: boolean;
}) => {
  if (is404) {
    return {};
  }

  const languageAlternates = resourceAlternateSummary
    ? buildAlternateReferences(origin, resourceAlternateSummary)
    : buildAlternateReferences(origin, { path: pathname, localesWithContent: locales }); // default to generating an alternate for all locales (the OG behaviour)

  const canonical = buildCanonicalUrl({ origin, path: pathname, locale: currentLocale });
  return {
    canonical,
    languageAlternates,
  };
};

// eslint-disable-next-line no-empty-pattern
export const getBreadcrumbsItems = (items: { url: string; name: string }[]) => {
  const basePath = items?.[0]?.url;
  const crumbs: ItemListElements[] = items.map((i, index) => {
    return {
      item: index === 0 ? basePath : `${basePath}${i.url}`,
      name: i.name,
      position: index + 1,
    } as ItemListElements;
  });
  return crumbs;
};

// skip SEO for PLP and PDP pages
// TODO: remove this after we fixed the 3rd party script injections to be after meta next-head-count
export const shouldSkipRootSeo = (pathname?: string): boolean => {
  if (!pathname) {
    return false;
  }
  return /^\/product/i.test(pathname);
};
