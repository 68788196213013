import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';

export function removeEmpty<T extends Record<string, any>>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj.filter((element) => !!element).map((element) => removeEmpty(element)) as any;
  }

  if (isObject(obj)) {
    const n: Record<string, any> = {};

    Object.keys(obj).forEach((key) => {
      if (!isNil((obj as any)[key])) {
        n[key] = removeEmpty((obj as any)[key]);
      }
    });

    return n as any;
  }

  return obj;
}
