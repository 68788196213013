import { FC } from 'react';

import { RendererBasicConfig, RendererConfig } from '@components/screen/types';
import { Maybe } from '@lib/utility-types';

export function getRendererFn(renderers: Map<string, RendererConfig>) {
  return function Renderer(config: RendererBasicConfig) {
    // eslint-disable-next-line func-names
    return function <T>(component: FC<T>): FC<T> {
      const renderer = registerRenderer({ ...config, component }, renderers);
      return renderer.component;
    };
  };
}

export function getResolveRendererFn(renderers: Map<string, RendererConfig>) {
  return function resolveRenderer(path: string): Maybe<RendererConfig> {
    return renderers.get(path);
  };
}

function registerRenderer<T>(config: RendererConfig<T>, renderers: Map<string, RendererConfig>) {
  if (!config.component) {
    throw new TypeError('config.component is required');
  }

  if (!config.name) {
    throw new TypeError('config.name is required');
  }

  if (renderers.has(config.name)) {
    throw new Error(`The component with name "${config.name}" has already exists, please try another name!`);
  }

  renderers.set(config.name, config);
  return config;
}
